<template>
  <div class="check">
    <div class="flex">
      <div class="flexBox">
        <div class="flexTitle">发票号码</div>
        <el-input v-model="search_param.pi_no"></el-input>
      </div>
      <div class="flexBox">
        <div class="flexTitle">开票年月</div>
        <el-date-picker
          v-model="search_param.pi_date"
          type="datetimerange"
          range-separator="~"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
          style="width: 100%"
        />
      </div>
      <div class="flexBox">
        <div class="flexTitle">供货方税号</div>
        <el-input v-model="search_param.identification"></el-input>
      </div>
    </div>
    <div class="flex">
      <div class="flexBox">
        <div class="flexTitle">状态</div>
        <el-select
          v-model="search_param.status"
          placeholder="全部状态"
          style="width: 100%"
          clearable
        >
          <el-option key="1" label="未匹配" :value="0" />
          <el-option key="2" label="部分匹配" :value="1" />
          <el-option key="3" label="已匹配" :value="2" />
        </el-select>
      </div>
      <div
        class="flexBox"
        style="margin-right: 100px; flex: 2; text-align: right"
      >
        <el-button
          v-debounce
          type="primary"
          style="margin-left: 5px"
          @click="getList"
          >查询</el-button
        >
        <el-button v-debounce @click="reset">重置</el-button>
      </div>
    </div>
    <div class="flex" style="margin-left: 10px">
      <el-button
        v-debounce
        type="primary"
        @click="
          initDetail();
          dialogVisible = true;
        "
        >新增</el-button
      >
      <el-button v-debounce type="primary" @click="cahngeIt">修改</el-button>
      <el-button v-debounce type="primary" @click="showDialog = true"
        >导入</el-button
      >
      <el-button v-debounce type="primary" @click="deleteIt">删除</el-button>
      <el-button v-debounce type="primary" @click="matchIt">匹配位置</el-button>
    </div>
    <el-table
      :data="tableData"
      :border="1"
      :header-cell-style="{ 'background-color': '#f6f6f6' }"
      :cell-style="{ fontSize: '12px' }"
      style="width: 100%"
      height="460"
      @header-dragend="headerDragend"
      @selection-change="handleSelectionChange"
      @row-click="rowClick"
      @sort-change="handleSortChange"
      :header-cell-class-name="handleHeaderClass"
    >
      <el-table-column type="selection" label="序号" width="55" />
      <el-table-column label="状态" min-width="80px" show-overflow-tooltip>
        <template v-slot:default="scope">
          <div>{{ scope.row.status || "暂无" }}</div>
        </template>
      </el-table-column>
      <el-table-column label="发票代码" min-width="100px" show-overflow-tooltip>
        <template v-slot:default="scope">
          <div>{{ scope.row.pi_code || "暂无" }}</div>
        </template>
      </el-table-column>
      <el-table-column label="发票号码" min-width="120px" show-overflow-tooltip>
        <template v-slot:default="scope">
          <div>{{ scope.row.pi_no || "暂无" }}</div>
        </template>
      </el-table-column>
      <el-table-column
        label="供货方税号"
        min-width="120px"
        show-overflow-tooltip
      >
        <template v-slot:default="scope">
          <div>{{ scope.row.identification || "暂无" }}</div>
        </template>
      </el-table-column>
      <el-table-column label="开票日期" min-width="120px" show-overflow-tooltip>
        <template v-slot:default="scope">
          <div>{{ scope.row.pi_date || "暂无" }}</div>
        </template>
      </el-table-column>
      <el-table-column label="类型" min-width="100px" show-overflow-tooltip>
        <template v-slot:default="scope">
          <div>
            {{ scope.row.tax_type }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        label="计税金额"
        min-width="120px"
        prop="order_by_tax_amount"
        sortable="custom"
        show-overflow-tooltip
      >
        <template v-slot:default="scope">
          <div>{{ scope.row.tax_amount }}</div>
        </template>
      </el-table-column>
      <el-table-column label="已占金额" min-width="120px" show-overflow-tooltip>
        <template v-slot:default="scope">
          <div>{{ scope.row.occupied_amount }}</div>
        </template>
      </el-table-column>
      <el-table-column label="剩余金额" min-width="120px" show-overflow-tooltip>
        <template v-slot:default="scope">
          <div>
            {{
              Number(scope.row.tax_amount) - Number(scope.row.occupied_amount)
            }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="税额" min-width="120px" show-overflow-tooltip>
        <template v-slot:default="scope">
          <div>{{ scope.row.tax }}</div>
        </template>
      </el-table-column>
      <el-table-column label="已占税额" min-width="120px" show-overflow-tooltip>
        <template v-slot:default="scope">
          <div>{{ scope.row.occupied_tax }}</div>
        </template>
      </el-table-column>
    </el-table>
    <div class="demo-pagination-block">
      <el-pagination
        v-model:current-page="currentPage"
        :page-sizes="[10, 30, 50, 100, 500]"
        v-model:page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalCount"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      />
    </div>
    <!-- 提示框 -->
    <el-dialog
      v-model="dialogVisible"
      width="480px"
      :title="rowDetail.pi_id ? '修改' : '新增'"
    >
      <div>
        <div class="inputStyle">
          <div class="span1">发票代码</div>
          <div class="span2">
            <el-input v-model="rowDetail.pi_code" clearable> </el-input>
          </div>
        </div>
        <div class="inputStyle">
          <div class="span1">*发票号码</div>
          <div class="span2">
            <el-input v-model="rowDetail.pi_no" clearable> </el-input>
          </div>
        </div>
        <div class="inputStyle">
          <div class="span1">*开票日期</div>
          <div class="span2">
            <el-date-picker
              v-model="rowDetail.pi_date"
              type="date"
              value-format="X"
              style="width: 100%"
            />
          </div>
        </div>
        <div class="inputStyle">
          <div class="span1">*类型</div>
          <div class="span2">
            <el-select
              v-model="rowDetail.tax_type"
              placeholder="全部状态"
              style="width: 100%"
              clearable
            >
              <el-option key="2" label="增值税发票" :value="1" />
              <el-option key="3" label="消费税发票" :value="2" />
            </el-select>
          </div>
        </div>
        <div class="inputStyle">
          <div class="span1">*计税金额</div>
          <div class="span2">
            <el-input v-model="rowDetail.tax_amount" clearable> </el-input>
          </div>
        </div>
        <div class="inputStyle">
          <div class="span1">*税额</div>
          <div class="span2">
            <el-input v-model="rowDetail.tax" clearable> </el-input>
          </div>
        </div>
        <div class="inputStyle" v-if="rowDetail.pi_id">
          <div class="span1">*已占用金额</div>
          <div class="span2">
            <el-input v-model="rowDetail.occupied_amount" disabled clearable>
            </el-input>
          </div>
        </div>
        <div class="inputStyle" v-if="rowDetail.pi_id">
          <div class="span1">*已占用税额</div>
          <div class="span2">
            <el-input v-model="rowDetail.occupied_tax" disabled clearable>
            </el-input>
          </div>
        </div>
        <div class="inputStyle">
          <div class="span1">*销方识别号</div>
          <div class="span2">
            <el-input v-model="rowDetail.identification" clearable> </el-input>
          </div>
        </div>
        <div style="text-align: right; margin: 20px 45px 0 0">
          <el-button v-debounce type="primary" @click="addPurchase"
            >保存</el-button
          >
          <el-button v-debounce @click="dialogVisible = false">关闭</el-button>
        </div>
      </div>
    </el-dialog>
    <el-dialog v-model="dialogVisible1" width="480px" title="提示">
      <div>确认删除数据？</div>
      <div style="text-align: right; margin: 20px 30px 0 0">
        <el-button v-debounce type="primary" @click="sureDelete"
          >确认</el-button
        >
        <el-button v-debounce @click="dialogVisible1 = false">取消</el-button>
      </div>
    </el-dialog>
    <el-dialog v-model="dialogVisible2" width="650px" title="查看匹配位置">
      <div>
        <el-table
          :data="matchData"
          :border="1"
          :header-cell-style="{ 'background-color': '#f6f6f6' }"
          :cell-style="{ fontSize: '12px' }"
          style="width: 100%"
          height="460"
        >
          <el-table-column type="index" label="序号" width="55" />
          <el-table-column
            label="预录入编号"
            min-width="80px"
            show-overflow-tooltip
          >
            <template v-slot:default="scope">
              <div>{{ scope.row.dec_tmp_no || "暂无" }}</div>
            </template>
          </el-table-column>
          <el-table-column
            label="报关单商品项号"
            min-width="120px"
            show-overflow-tooltip
          >
            <template v-slot:default="scope">
              <div>{{ scope.row.gnum || "暂无" }}</div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div style="text-align: right; margin: 20px 30px 0 0">
        <el-button v-debounce type="primary" @click="dialogVisible2 = false"
          >确认</el-button
        >
        <el-button v-debounce @click="dialogVisible2 = false">取消</el-button>
      </div>
    </el-dialog>
    <!-- 导入 -->
    <el-dialog
      v-model="showDialog"
      @close="closeUpload"
      title="上传文件"
      width="650px"
    >
      <div>
        <div
          style="display: flex; justify-content: center; align-items: center"
        >
          <el-upload
            :limit="1"
            :auto-upload="false"
            :on-change="handleUpload"
            style="text-align: center"
            ref="uploadFileRef"
          >
            <el-button v-debounce type="primary">选择文件</el-button>
          </el-upload>
        </div>
        <div class="el-upload__tip">
          <a :href="tUrl" class="dowload">点击下载模板</a>
        </div>
        <div v-if="uploadList.length > 0">
          <ul>
            <li v-for="(file, index) in uploadList" :key="index">
              {{ file.name }}
            </li>
          </ul>
        </div>
      </div>
      <span class="dialog-footer">
        <el-button
          v-debounce
          @click="
            closeUpload();
            showDialog = false;
          "
          >取 消</el-button
        >
        <el-button v-debounce type="primary" @click="handleConfirm"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script setup>
import { ref, reactive } from "vue";
import {
  listPurchaseInvoice,
  insertPurchaseInvoice,
  getPurchaseInvoice,
  updatePurchaseInvoice,
  deletePurchaseInvoice,
  selectMatchingGoods,
  importPurchaseInvoice,
} from "@/api/import/taxRefund.js";
import { ElMessage } from "element-plus";

//查询参数对象
const search_param = reactive({
  pi_no: "",
  pi_date: [],
  identification: "",
  status: "",
  order_by_tax_amount: "",
  page: 1,
  size: 10,
});

// 重置功能
const reset = () => {
  search_param.pi_no = "";
  search_param.pi_date = [];
  search_param.identification = "";
  search_param.status = "";
  search_param.order_by_tax_amount = "";
};

//分页逻辑
const currentPage = ref(1);
const pageSize = ref(10);
const totalCount = ref(0);
const handleCurrentChange = (val) => {
  currentPage.value = val;
  getList({ cP: currentPage.value, size: pageSize.value, tips: true });
};
const handleSizeChange = (val) => {
  pageSize.value = val;
  getList({ cP: currentPage.value, size: pageSize.value, tips: true });
};

// 表格数据
let tableData = ref([]);
let multipleSelection = ref([]);
const handleSelectionChange = (val) => {
  multipleSelection.value = val;
};
const getList = ({
  cP = currentPage.value,
  size = pageSize.value,
  tips = false,
}) => {
  currentPage.value = cP;
  pageSize.value = size;
  listPurchaseInvoice({
    pi_no: search_param.pi_no || "",
    pi_date: search_param.pi_date || [],
    identification: search_param.identification || "",
    status: search_param.status === "" ? null : search_param.status,
    order_by_tax_amount: search_param.order_by_tax_amount || "",
    page: cP,
    size: size,
  }).then((res) => {
    if (res.code == 200) {
      tableData.value = res.data.list;
      totalCount.value = res.data.count;
      if (!tips)
        ElMessage({
          grouping: true,
          showClose: true,
          message: res.msg,
          type: "success",
        });
    }
  });
};
const dialogVisible = ref(false);
const dialogVisible1 = ref(false);
const dialogVisible2 = ref(false);

const rowDetail = ref({
  pi_code: "", //发票代码
  pi_no: "", //发票号码
  pi_date: "", //开票日期
  tax_amount: "", //计税金额
  tax: "", //税额
  identification: "", //销方识别号
  tax_type: "",
});
const initDetail = () => {
  Object.keys(rowDetail.value).map((item) => (rowDetail.value[item] = ""));
};
// 添加进货发票
const addPurchase = async () => {
  let res;
  if (!rowDetail.value.pi_id) {
    res = await insertPurchaseInvoice({
      pi_code: rowDetail.value.pi_code || "",
      pi_no: rowDetail.value.pi_no || "",
      pi_date: rowDetail.value.pi_date || "",
      tax_amount: rowDetail.value.tax_amount || "",
      tax: rowDetail.value.tax || "",
      identification: rowDetail.value.identification || "",
      tax_type: rowDetail.value.tax_type || "",
    });
  } else {
    res = await updatePurchaseInvoice({
      pi_id: rowDetail.value.pi_id || "",
      pi_code: rowDetail.value.pi_code || "",
      pi_no: rowDetail.value.pi_no || "",
      pi_date: rowDetail.value.pi_date || "",
      tax_amount: rowDetail.value.tax_amount || "",
      tax: rowDetail.value.tax || "",
      identification: rowDetail.value.identification || "",
      tax_type: rowDetail.value.tax_type || "",
    });
  }
  if (res.code == 200) {
    ElMessage({
      grouping: true,
      showClose: true,
      message: res.msg,
      type: "success",
    });
    dialogVisible.value = false;
    initDetail();
    getList({ cP: currentPage.value, size: pageSize.value, tips: true });
  }
};

// 获取详情
const getDetail = async () => {
  let res = await getPurchaseInvoice({ pi_id: rowDetail.value?.pi_id || "" });
  if (res.code == 200) {
    rowDetail.value = res.data;
    rowDetail.value.pi_date = new Date(res.data.pi_date).getTime() / 1000;
  }
};

const cahngeIt = async () => {
  if (multipleSelection.value.length != 1) {
    return ElMessage({
      grouping: true,
      showClose: true,
      message: "请选择单条数据",
      type: "error",
    });
  }
  rowDetail.value = multipleSelection.value[0];
  await getDetail();
  dialogVisible.value = true;
};

const deleteIt = async () => {
  if (multipleSelection.value.length != 1) {
    return ElMessage({
      grouping: true,
      showClose: true,
      message: "请选择单条数据",
      type: "error",
    });
  }
  dialogVisible1.value = true;
};

const sureDelete = async () => {
  let res = await deletePurchaseInvoice({
    pi_id: multipleSelection.value[0].pi_id || "",
  });
  if (res.code == 200) {
    ElMessage({
      grouping: true,
      showClose: true,
      message: res.msg,
      type: "success",
    });
    dialogVisible1.value = false;
    getList({ cP: currentPage.value, size: pageSize.value, tips: true });
  }
};

const matchIt = async () => {
  if (multipleSelection.value.length != 1) {
    return ElMessage({
      grouping: true,
      showClose: true,
      message: "请选择单条数据",
      type: "error",
    });
  }
  sureMatch();
};

const matchData = ref([]);
const sureMatch = async () => {
  let res = await selectMatchingGoods({
    pi_id: multipleSelection.value[0].pi_id || "",
  });
  if (res.code == 200) {
    matchData.value = res.data.list;
    dialogVisible2.value = true;
  }
};

//上传
const tUrl = process.env.VUE_APP_BASE_URL + "demo/进货明细发票导入模板.xlsx";
const showDialog = ref(false);
const uploadFileRef = ref();
const uploadList = ref([]);
const handleUpload = (fileList) => {
  uploadList.value = fileList;
};
// 关闭上传框
const closeUpload = () => {
  uploadFileRef.value.clearFiles();
};
const handleConfirm = async () => {
  await importPurchaseInvoice(uploadList.value.raw).then((res) => {
    if (res.code === 200) {
      ElMessage({
        grouping: true,
        showClose: true,
        message: res.msg || "导入成功",
        type: "success",
      });
      showDialog.value = false;
      getList({ cP: currentPage.value, size: pageSize.value, tips: true });
    }
  });
};

// 拉伸表格
const headerDragend = (row, column, cell) => {
  if (row < cell.minWidth) cell.width = cell.minWidth;
};

//排序样式
const handleHeaderClass = ({ column }) => {
  const { property } = column;
  if (sortState[property]) {
    column.order = sortState[property];
  }
};
//初始状态
const sortState = {
  order_by_tax_amount: null,
};
const handleSortChange = ({ prop, order }) => {
  // 复制当前的排序状态
  sortState[prop] = order;
  // 更新所有列的排序状态
  if (sortState.order_by_tax_amount === "ascending") {
    search_param.order_by_tax_amount = 1;
  } else if (sortState.order_by_tax_amount === "descending") {
    search_param.order_by_tax_amount = 2;
  } else {
    search_param.order_by_tax_amount = 0;
  }
  getList({ cP: currentPage.value, size: pageSize.value, tips: true });
};
</script>

<style scoped>
.check {
  color: #666;
  font-size: 14px;
}
.flex {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.flexBox {
  flex: 1;
  display: flex;
  line-height: 30px;
  text-align: right;
  margin-right: 50px;
}
.flexTitle {
  width: 110px;
  text-align: center;
}
/*分页样式 */
.demo-pagination-block {
  margin-top: 10px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.inputStyle {
  margin-bottom: 10px;
  margin-left: 50px;
  line-height: 30px;
  display: flex;
  text-align: right;
  flex: 1;
}
.inputStyle .span1 {
  width: 80px;
  padding-right: 15px;
  line-height: 30px;
}
.inputStyle .span2 {
  width: 250px;
}

.inputStyle .span3 {
  flex: 1;
  margin-left: 20px;
  font-size: 13px;
}

:deep(.el-tabs__nav-wrap::after) {
  background-color: #fff !important;
}
.demo-tabs > .el-tabs__content {
  color: #6b778c;
  font-size: 32px;
  font-weight: 600;
}
:deep(.el-checkbox__input.is-disabled.is-checked .el-checkbox__inner) {
  background-color: var(--el-checkbox-checked-bg-color);
  border-color: var(--el-checkbox-checked-input-border-color);
}
:deep(.el-checkbox__input.is-disabled.is-checked .el-checkbox__inner::after) {
  border-color: #fff;
}
:deep(.el-checkbox__input.is-disabled + span.el-checkbox__label) {
  color: #606266;
}
</style>
